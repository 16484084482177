define("Automatons/automatons/2002", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2002,
    type: "automatons",
    attributes: {
      name: "UPS Click-to-Call Agent-Pushed Form",
      description: "CMRASI-2837",
      initialNode: "node::2001",
      style: "style::2001"
    }
  };
});