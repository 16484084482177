define("Automatons/nodes/2001", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2001,
    type: "nodes",
    attributes: {
      name: "UPS Click-to-Call Agent-Pushed Form",
      type: "survey",
      header: "Provide details below and we'll connect you with a UPS customer care representative.",
      inputs: [{
        name: "First_Name",
        type: "text",
        dataErrorMesssage: "Alphanumeric characters only",
        isRequired: true,
        label: "First name",
        mask: true,
        pattern: "^[a-zA-Z-']*$",
        requiredErrorMesssage: "This field is required",
        size: "flex-45"
      }, {
        name: "Last_Name",
        type: "text",
        dataErrorMesssage: "Alphanumeric characters only",
        isRequired: true,
        label: "Last name",
        mask: true,
        pattern: "^[a-zA-Z-']*$",
        requiredErrorMesssage: "This field is required",
        size: "flex-45"
      }, {
        name: "Phone_Number",
        type: "text",
        dataErrorMesssage: "A valid 10-digit phone number is required",
        isRequired: true,
        label: "Phone number",
        mask: true,
        pattern: "^[2-9]\\d{9}$",
        requiredErrorMesssage: "A valid 10-digit phone number is required",
        size: "flex-100"
      }],
      onExit: function onExit() {
        api.sendFormDataToAgent();
      },
      template: "template::2001"
    }
  };
});