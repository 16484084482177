define("Automatons/nodes/2002", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2002,
    type: "nodes",
    attributes: {
      name: "UPS Click To Call Submit",
      isOutcomeNode: 1,
      onEntry: function onEntry() {
        var modifiedData = api.automaton.Automaton.Data.CRM.getResponseValues();
        // console.log('modifiedData:');
        // console.log(modifiedData);

        var modifiedData = {
          "first_name": modifiedData['First_Name'].indexOf('[') >= 0 ? modifiedData['First_Name'].slice(modifiedData['First_Name'].indexOf('[') + 1, modifiedData['First_Name'].indexOf(']')) : modifiedData['First_Name'],
          "last_name": modifiedData['Last_Name'].indexOf('[') >= 0 ? modifiedData['Last_Name'].slice(modifiedData['Last_Name'].indexOf('[') + 1, modifiedData['Last_Name'].indexOf(']')) : modifiedData['Last_Name'],
          "phone_number": modifiedData['Phone_Number'].indexOf('[') >= 0 ? modifiedData['Phone_Number'].slice(modifiedData['Phone_Number'].indexOf('[') + 1, modifiedData['Phone_Number'].indexOf(']')) : modifiedData['Phone_Number']
        };

        // Strip out all characters except the last 10 numbers
        if (modifiedData && modifiedData["phone_number"]) {
          modifiedData["phone_number"] = modifiedData["phone_number"].replace(/\D/g, '').slice(-10);
        }

        var data = JSON.stringify(modifiedData);

        Inq.sendAutomatonMessage('datapass', {
          agentID: inqFrame.Inq.FlashPeer.getAgentID(),
          engagementID: inqFrame.Inq.CHM.getChatID(),
          datapass: data
        });

        // console.log('submitted');
        // console.log("data", data);
        // console.log(data);

        setTimeout(function () {
          var message = new inqFrame.com.inq.flash.client.data.ChatCommunicationMessage(inqFrame.Application.application.applicationController.chat, "##ClickToCallForm");
          message.addProperty(inqFrame.com.inq.flash.client.data.MessageFields.KEY_RETURN_RECEIPT, 0);
          inqFrame.Application.application.applicationController.sendChatCommunicationMessage(message, 0);
        }, 1000);

        api.automaton.Automaton.close();
      },
      panel: {
        height: 60
      }
    }
  };
});