define("Automatons/nodes/2006", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2006,
    type: "nodes",
    attributes: {
      name: "UPS SMS Form - Prechat",
      type: "survey",
      header: "Promptly fill out the form to connect with a UPS representative.",
      inputs: [{
        name: "First_Name",
        type: "text",
        dataErrorMesssage: "Alpha characters only",
        isRequired: true,
        label: "First name",
        mask: true,
        pattern: "^[a-zA-Z-']*$",
        requiredErrorMesssage: "This field is required",
        size: "flex-45 fixed-fn"
      }, {
        name: "Last_Name",
        type: "text",
        dataErrorMesssage: "Alpha characters only",
        isRequired: true,
        label: "Last name",
        mask: true,
        pattern: "^[a-zA-Z-']*$",
        requiredErrorMesssage: "This field is required",
        size: "flex-45 fixed-ln"
      }, {
        name: "Country_Code",
        type: "text",
        disabled: true,
        label: "",
        size: "flex-15 fixed-cc",
        value: "US"
      }, {
        name: "Phone_Number",
        type: "text",
        dataErrorMesssage: "Valid cell phone number required for country",
        isRequired: true,
        label: "Cell phone number",
        mask: true,
        maxlength: 14,
        pattern: "^(\\(?[2-9]\\d{2}\\)?[\\s-]?\\d{3}(-)?\\d{4})$",
        requiredErrorMesssage: "Valid cell phone number required for country",
        size: "flex-90 fixed-pn"
      }],
      onEntry: function onEntry() {
        setTimeout(checkContainer, 500);
        var previousPhone = '';

        function phoneFormat(field) {
          var specialCharCount = (field.value.match(/\D/g) || []).length;
          var cursorPosition = field.selectionStart;
          var input = field.value.replace(/\D/g, '');
          var size = input.length;
          if (input.substring(0, 1) == null) {
            // this condition is if user enters 1 then it will continue the path below and formatting is in the else statement
            if (size === 0) {
              input = "";
            } else if (size < 4) {
              input = "+" + input.substring(0, 1) + " (" + input.substring(1);
            } else if (size < 8) {
              input = "+" + input.substring(0, 1) + " (" + input.substring(1, 4) + ") " + input.substring(4);
            } else if (size < 12) {
              input = "+" + input.substring(0, 1) + " (" + input.substring(1, 4) + ") " + input.substring(4, 7) + "-" + input.substring(7, 11);
            }
          } else {
            // this condition is if they enter any other number greater than 1
            if (size > 7 && size < 11) {
              input = "(" + input.substring(0, 3) + ") " + input.substring(3, 6) + "-" + input.substring(6);
            } else if (size > 3 && size < 8) {
              input = input.substring(0, 3) + "-" + input.substring(3);
            }
          }
          if (input !== previousPhone) {
            previousPhone = input;
            var specialCharDiff = (input.match(/\D/g) || []).length - specialCharCount;
            cursorPosition += specialCharDiff;
            field.value = input;
            field.selectionStart = cursorPosition;
            field.selectionEnd = cursorPosition;
          }
        }

        function checkContainer() {
          var $viewport = api.automaton.Automaton.View.$viewport;
          var phoneNumberFieldset = $('input[name="Phone_Number"]', $viewport);

          phoneNumberFieldset.on('input', function () {
            phoneFormat($(this)[0]); // get the current value of the input field.
          });

          var tcCheckbox = $(".inq-2003-tc-checkbox", $viewport);
          var submitButton = $(".inq-2003-button-submit", $viewport);

          tcCheckbox.change(function (e) {
            if ($(this).prop('checked')) {
              submitButton.removeClass('inq-2003-disable-button');
            } else {
              submitButton.addClass('inq-2003-disable-button');
            }
          });
        }
      },
      onExit: function onExit() {
        api.sendFormDataToAgent();
      },
      template: "template::2003"
    }
  };
});