define("Automatons/automatons/2003", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2003,
    type: "automatons",
    attributes: {
      name: "UPS SMS Form - Prechat",
      description: "CMRGEN-27971",
      initialNode: "node::2006",
      style: "style::2005"
    }
  };
});