define("Automatons/nodes/2008", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2008,
    type: "nodes",
    attributes: {
      name: "UPS PRECHAT SMS Cancel",
      isOutcomeNode: 1,
      onEntry: function onEntry() {
        var message = new inqFrame.com.inq.flash.client.data.ChatCommunicationMessage(inqFrame.Application.application.applicationController.chat, "##PRECHATSMSCANCEL");
        message.addProperty(inqFrame.com.inq.flash.client.data.MessageFields.KEY_RETURN_RECEIPT, 0);
        inqFrame.Application.application.applicationController.sendChatCommunicationMessage(message, 0);

        api.automaton.Automaton.close();
      },
      panel: {
        height: 60
      }
    }
  };
});